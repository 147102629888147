$flexembed-ratios: (
	3by1:	(3,1),
	2by1:	(2,1),
	16by9:	(16,9),
	4by3:	(4,3)
) !default;

/**
 * Flexible media embeds
 *
 * For use with media embeds - such as videos, slideshows, or even images -
 * that need to retain a specific aspect ratio but adapt to the width of their
 * containing element.
 *
 * Based on: http://alistapart.com/article/creating-intrinsic-ratios-for-video
 */

@mixin flexembed($ratios: $flexembed-ratios, $width: 0, $height: 0) {
	display: block;
	overflow: hidden;
	position: relative;

	/**
	 * The aspect-ratio hack is applied to an empty element because it allows
	 * the component to respect `max-height`. Default aspect ratio is 1:1.
	 */
	&__ratio {
		display: block;
		width: 100%;

		@if ($width == 0) {
			padding-bottom: 100%;

			@if length($ratios) {
				@each $label, $ratio in $ratios {
					&-ratio-#{$label} {
						padding-bottom: percentage(#{nth($ratio,1)} / #{nth($ratio,2)});
					}
				}

			} @else {
				@error '$ratios is empty and no width/height alternative was supplied.';
			}

		} @else {
			padding-bottom: percentage($height / $width);
		}
	}

	&__body {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}

}