html, body {
    font-size: $base-font-size;
}

body {
    font-family: $font-family-sans-serif;
    background: $color-black;
    color: $color-white-smoke;
    cursor: auto;
    font-size: $base-font-size;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: $base-line-height;
    letter-spacing: 1px;
    margin: 0;
    padding: {
        left: 0;
        right: 0;
        top: vr(2);
        bottom: 0;
    }
}
