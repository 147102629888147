@charset "UTF-8";
/* Relative weights ref:
   https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#Meaning_of_relative_weights
 */
/**
 * Flexible media embeds
 *
 * For use with media embeds - such as videos, slideshows, or even images -
 * that need to retain a specific aspect ratio but adapt to the width of their
 * containing element.
 *
 * Based on: http://alistapart.com/article/creating-intrinsic-ratios-for-video
 */
@import url(../../node_modules/normalize.css/normalize.css);
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  /*
     * fix links with svg inside in MS Edge:
     * http://stackoverflow.com/questions/35367445/can-only-click-edges-of-svg-image
     */
  /**
     * Suppress the focus outline on links that cannot be accessed via keyboard.
     * This prevents an unwanted focus outline from appearing around elements that
     * might still respond to pointer events.
     */
}

a[name] {
  position: relative;
  display: block;
  width: 0;
  height: 0;
}

a[name]:focus, a[name]:hover {
  outline: 0 none;
}

a[name]:focus:after, a[name]:hover:after {
  position: absolute;
  top: 0.75rem;
  left: 0;
  content: attr(aria-label);
  display: block;
  white-space: nowrap;
  line-height: 1em;
  color: #0000FF;
  background: #FFFFFF;
  border: 0.0625rem solid #0000FF;
  padding: 0.1875rem;
}

a object,
a svg {
  pointer-events: none;
}

a [tabindex="-1"]:focus {
  outline: none !important;
}

div, p,
dl, dt, dd,
ol, ul, li,
h1, h2, h3, h4, h5, h6,
figure, pre, form, blockquote,
table, th, td {
  margin: 0;
  padding: 0;
}

html, body {
  font-size: 100%;
}

body {
  font-family: "Open Sans", sans-serif;
  background: #000000;
  color: #F5F5F5;
  cursor: auto;
  font-size: 100%;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 1px;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1.5rem;
  padding-bottom: 0;
}

ol,
ul {
  list-style: none;
}

.Site {
  max-width: 25rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

@media (min-height: 601px) {
  .Site {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.Site__title {
  font-family: "Share Tech Mono", monospace;
  text-transform: uppercase;
  font-weight: 700;
  border: 0.375rem solid #F5F5F5;
  display: inline-block;
  padding: 3px 12px;
  white-space: nowrap;
  margin-bottom: 2.625rem;
  font-size: 2.625rem;
}

.Site__delimiter {
  visibility: hidden;
  position: relative;
  display: inline-block;
}

.Site__delimiter:after {
  content: '♦';
  visibility: visible;
  position: absolute;
  font-size: 0.5em;
  margin-top: -0.0625rem;
  line-height: 1;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.Site__who {
  font-family: "Share Tech Mono", monospace;
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 1.125rem;
}

.Site__roles {
  font-size: 1.125rem;
  line-height: 1.875rem;
}

.Site__roles:after {
  content: '';
  display: block;
  margin-top: 1.125rem;
  margin-bottom: 1.125rem;
  margin-left: auto;
  margin-right: auto;
  width: 1.875rem;
  border-bottom: 0.125rem solid #F5F5F5;
}

.Site__where {
  font-size: 1.125rem;
  line-height: 1.875rem;
  font-weight: 400;
}

.Site__contacts {
  font-size: 1.125rem;
  line-height: 1.875rem;
}

.Site__link:active, .Site__link:focus, .Site__link:hover {
  color: #FFFFFF;
}

.Site__link:active, .Site__link:hover {
  text-decoration: underline;
}

.Site__link:focus {
  outline-color: rgba(255, 255, 255, 0.75);
}

.Site__image {
  display: block;
  margin-top: 3rem;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.u-visuallyhidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
