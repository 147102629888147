.Site {
    max-width: rem(400);
    margin: {
        left: auto;
        right: auto;
    }
    text-align: center;

    @media (min-height: 601px) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    &__title {
        font-family: $font-family-serif;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        border: vr(0.5) solid $color-white-smoke;
        display: inline-block;
        padding: 3px 12px;
        white-space: nowrap;
        margin-bottom: vr(3.5);
        font-size: rem(42);
    }

    &__delimiter {
        visibility: hidden;
        position: relative;
        display: inline-block;

        &:after {
            content: '♦';
            visibility: visible;
            position: absolute;
            font-size: 0.5em;
            margin-top: rem(-1);
            line-height: 1;
            top: 50%;
            transform: translate(-50%,-50%);
            left: 50%;
        }
    }

    &__who {
        font-family: $font-family-serif;
        font-size: rem(32);
        text-transform: uppercase;
        margin-bottom: vr(1.5);
    }

    &__what {

    }

    &__roles {
        font-size: rem(18);
        line-height: vr(2.5);

        &:after {
            content: '';
            display: block;
            margin: {
                top: vr(1.5);
                bottom: vr(1.5);
                left: auto;
                right: auto;
            }
            width: vr(2.5);
            border-bottom: rem(2) solid $color-white-smoke;
        }
    }

    &__cto {

    }

    &__engineer {

    }

    &__where {
        font-size: rem(18);
        line-height: vr(2.5);
        font-weight: $font-weight-normal;
    }

    &__contacts {
        font-size: rem(18);
        line-height: vr(2.5);
    }

    &__contact {

        &--phone {

        }

        &--email {

        }

        &--location {

        }
    }

    &__link {

        &:active,
        &:focus,
        &:hover {
            color: $color-white;
        }

        &:active,
        &:hover {
            text-decoration: underline;
        }

        &:focus {
            outline-color: rgba($color-white, 0.75);
        }

        &--phone {

        }

        &--email {

        }
    }

    &__image {
        display: block;
        margin-top: vr(4);
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}
