// Visually hiding content that will be read by a screen reader
// @see https://webaim.org/techniques/css/invisiblecontent/#absolutepositioning
.u-visuallyhidden {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}
