a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;

    &[name] {
        position: relative;
        display: block;
        width: 0;
        height: 0;

        &:focus,
        &:hover {
            outline: 0 none;

            &:after {
                position: absolute;
                top: vr(1);
                left: 0;
                content: attr(aria-label);
                display: block;
                white-space: nowrap;
                line-height: 1em;
                color: $color-link;
                background: $color-white;
                border: rem(1) solid $color-link;
                padding: rem(3);
            }
        }
    }

    /*
     * fix links with svg inside in MS Edge:
     * http://stackoverflow.com/questions/35367445/can-only-click-edges-of-svg-image
     */
    object,
    svg {
        pointer-events: none;
    }

    /**
     * Suppress the focus outline on links that cannot be accessed via keyboard.
     * This prevents an unwanted focus outline from appearing around elements that
     * might still respond to pointer events.
     */
    [tabindex="-1"]:focus {
        outline: none !important;
    }
}
